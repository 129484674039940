export const environment = {
  production: true,
  env: 'prod',
  mode: 'live',
  firebase : {
    apiKey: "AIzaSyCc5RQ9q_B1buB4P_IFDHkxnZc_01ZaJ0M",
    authDomain: "johndoe-et-fils.firebaseapp.com",
    databaseURL: "https://johndoe-et-fils.firebaseio.com",
    projectId: "johndoe-et-fils",
    storageBucket: "johndoe-et-fils.appspot.com",
    messagingSenderId: "926287540898",
    appId: "1:926287540898:web:28540deddc95ad2bf65dfb"
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoicGFydGVkaXMiLCJhIjoiY2tuZnJwcGhmMnk1NzJubnhzeW00MDZ3YyJ9.9UOBPuetSrAxsJmTsCvx7Q',
    style : 'mapbox://styles/partedis/cknrgwvax0s2p17ufy9sinqmj'
  },
  api:{
    site: 'https://johndoe-et-fils.com',
    agrid: 'https://agrid.johndoe-et-fils.com/agrid',
    api: 'https://johndoe-et-fils.com/xml/',
    apiv1: 'https://johndoe-et-fils.com/xml/api/v1',
  }
};
